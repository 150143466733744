<template>
  <div class="msglistcont">
    <ul class="messagelist" v-if="messageList && messageList.length > 0">
      <li
        v-for="(item, index) in messageList"
        :key="index"
        @click="goUrl(item.id, item.toPageType, item.messageTypeName)"
        :class="{ isread: item.isRead === 1 }"
      >
        <div class="iconfont" :class="[item.icon, item.class]">
          <span class="hongdian" v-if="item.isRead === 0"></span>
        </div>
        <div class="time">{{ item.sendTimeStr }}</div>
        <div class="type">{{ item.messageTypeName }}</div>
        <div class="title">{{ item.title }}</div>
        <div class="tips">{{ item.content }}</div>
      </li>
    </ul>
    <div class="nodata-box" v-else>
      <no-data :blankData="{ type: 'notdata', isShow: false }"></no-data>
    </div>
  </div>
</template>

<script>
import { homeallapp } from '@/config/config.js'
import { msgPath } from '@/config/msg.js'
import { msgList, msgRead } from '@/api/api_public'
import noData from '@/components/business/blank/blank'
export default {
  name: 'home',
  components: {
    noData
  },
  data() {
    return {
      homeallapp,
      msgPath,
      total: 0,
      messageList: [] //消息列表
    }
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          size: 4
        }
      }
    },
    msgtype: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.getMsgList()
  },
  watch: {
    childpaginationrens: {
      handler: function(val, oldval) {
        this.getMsgList()
      },
      deep: true
    },
    msgtype: function(val, oldval) {
      this.getMsgList()
    }
  },
  methods: {
    goUrl(id, path, name) {
      let paths = [301, 302, 303, 401, 402, 403]
      this.setMsgRead(id)
      if (path) {
        this.msgPath.forEach(ele => {
          if (ele.toPageType == path) {
            if (paths.includes(path)) {
              sessionStorage.removeItem('code')
              sessionStorage.removeItem('name')
              sessionStorage.setItem('code', ele.code)
              sessionStorage.setItem('name', name)
            }
            if ([501, 502].includes(path)) {
              this.$router.push({
                name: ele.path,
                query: {
                  moduleName: 'review'
                }
              })
            } else {
              this.$router.push({ path: ele.path })
            }
          }
        })
      }
    },
    async setMsgRead(id) {
      let { code, data } = await msgRead({ id })
      if (code === '000000') {
        this.getMsgList()
      }
    },
    getMsgList() {
      let params = {
        page: this.pagination.page,
        size: this.pagination.size,
        type: this.msgtype
      }
      msgList(params).then(result => {
        if (result && result.code === '000000') {
          this.messageList = result.data.list
          this.total = result.data.total
          this.messageList.forEach(e => {
            this.homeallapp.forEach(ele => {
              if (e.messageType === ele.msgtype) {
                e.icon = ele.icon
                e.class = ele.class
              }
            })
          })
        }
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.msglistcont {
  margin-top: 20px;
  .iconbg-yellow {
    background: $yellow;
  }
  .iconbg-green {
    background: $green;
  }
  .iconbg-blue {
    background: $blue;
  }
  .iconbg-red {
    background: $red;
  }
  .messagelist {
    li {
      padding: 18px 20px 18px 56px;
      border-bottom: 1px solid $linecolor;
      position: relative;
      line-height: 26px;
      cursor: pointer;
      background: #fff;
      &:hover {
        background: $hover-block;
      }
      .type,
      .time,
      .tips {
        color: $grey;
        font-size: 12px;
        line-height: 16px;
      }
      .time {
        position: absolute;
        right: 20px;
        top: 17px;
        line-height: 16px;
      }
      .title {
        font-size: 16px;
        line-height: 20px;
        color: $lightblack;
        margin: 8px 0 6px;
      }
      .tips {
        line-height: 16px;
        // color: $blue;
      }
      .iconfont {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        position: absolute;
        left: 20px;
        top: 13px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        .hongdian {
          display: block;
          width: 8px;
          height: 8px;
          background: $red;
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          border: 1px solid #fff;
        }
      }
    }
    li:last-child {
      border-bottom: none;
    }
    li.isread {
      .title,
      .tips {
        color: $grey;
      }
      .iconfont {
        background: $grey;
      }
    }
  }
  .nodata-box {
    padding: 50px 0;
  }
}
</style>
