//消息列表跳转
// #目标
// 101-工作目标-待我确认列表页
// 102-工作目标-待我考评列表页
// 103-工作目标-频道页
// #三清
// 201-三清两会-决议列表页
// 202-三清-频道页
// 203-两会-频道页
// 204-三清-审阅页
// #创新成果
// 301-创新成果-审批列表页
// 302-创新成果-我的发起 ‘全部’列表页
// 303-创新成果-频道页
// #团队贡献
// 401团队贡献-审批列表页
// 402团队贡献-我的发起 ‘全部’列表页
// 403团队贡献-频道页
// #组织绩效
// 501-组织绩效-参评组织考评页
// 502-组织绩效-个人绩效考评页
// #民主互评
// 601-民主互评-待我评价页
// 602-民主互评-待我审批列表页
// 603-民主互评-邀请评价人页面
// 604-民主互评-收到的评价页面
// 605-民主互评-我的团队页面
// #客户满意度
// 701-客户满意度-待我评价页面
// 702-客户满意度-待我审批页面
// 703-客户满意度-邀请评价人页面
// 704-客户满意度-客户满意度人员列表
// 705-客户满意度-投诉处理页面
// 706-客户满意度-感动处理页面
// #其他
// 801-消息-待办列表页
// 802-首页
export const msgPath = [
  {
    toPageType: 101,
    path: '/mytarget/evaluation?state=1&type=1'
  },
  {
    toPageType: 102,
    path: '/mytarget/evaluation?state=1&type=2'
  },
  {
    toPageType: 103,
    path: '/mytarget/mine'
  },
  {
    toPageType: 201,
    path: '/thrclear/conferenceres'
  },
  {
    toPageType: 202,
    path: '/thrclear/mine'
  },
  {
    toPageType: 203,
    path: '/thrclear/meeting'
  },
  {
    toPageType: 204,
    path: '/thrclear/mine'
  },
  {
    toPageType: 301,
    path: '/innovations/approval',
    code: 'innovate'
  },
  {
    toPageType: 302,
    path: '/innovations/initiate',
    code: 'innovate'
  },
  {
    toPageType: 303,
    path: '/innovations/channel',
    code: 'innovate'
  },
  {
    toPageType: 401,
    path: '/team/approval',
    code: 'contribute'
  },
  {
    toPageType: 402,
    path: '/team/initiate',
    code: 'contribute'
  },
  {
    toPageType: 403,
    path: '/team/channel',
    code: 'contribute'
  },
  {
    toPageType: 501,
    path: 'appraisal',
    code: 'review'
  },
  {
    toPageType: 502,
    path: 'appraisal',
    code: 'review'
  },
  {
    toPageType: 601,
    path: '/democracy/mine?moduleName=comment'
  },
  {
    toPageType: 602, //待我审批
    path: '/democracy/underlings?moduleName=approve'
  },
  {
    toPageType: 603,
    path: '/democracy/inviter?wayType=1'
  },
  {
    toPageType: 604,
    path: '/democracy/mine?moduleName=received'
  },
  {
    toPageType: 605,
    path: '/democracy/underlings?moduleName=myTeam'
  },
  {
    toPageType: 701,
    path: '/satisfaction/mine?moduleName=comment'
  },
  {
    toPageType: 702,
    path: '/satisfaction/underlings?moduleName=approve'
  },
  {
    toPageType: 703,
    path: '/satisfaction/inviter?inviterPersonNums=0'
  },
  {
    toPageType: 704,
    path: '/satisfaction/underlings?moduleName=underlings'
  },
  {
    toPageType: 705,
    path: '/satisfaction/underlings?moduleName=complaint'
  },
  {
    toPageType: 706,
    path: '/satisfaction/underlings?moduleName=touch'
  },
  {
    toPageType: 801,
    path: '/message/list'
  },
  {
    toPageType: 802,
    path: '/'
  }
]
