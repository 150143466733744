<template>
  <div class="content">
    <div class="content-left">
      <h3 class="title">常用应用</h3>
      <ul class="applist">
        <li v-for="(item, index) in homeusedapp" :key="index" @click="goAppUrl(item)">
          <span class="iconfont" :class="[item.icons, item.class]"><i v-if="item.isRedTips > 0 || (item.name === '会议决议' && noDoneNum > 0)"></i></span>
          {{ item.name }}
        </li>
      </ul>
      <h3 class="title">全部应用</h3>
      <ul class="applist" v-if="dimensionCfgList && dimensionCfgList.length > 0">
        <li v-for="(item, index) in dimensionCfgList" :key="index" @click="goUrl(item.path, item)">
          <span class="iconfont" :class="[item.icon, item.class]"><i v-if="item.redDot > 0"></i></span>
          {{ item.displayName }}
        </li>
      </ul>
      <h3 class="title">其他</h3>
      <ul class="applist">
        <li v-for="(item, index) in homeotherapp" :key="index" @click="goUrl(item.path)">
          <span class="iconfont" :class="[item.icon, item.class]"></span>
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="content-right">
      <div class="white-box">
        <h3 class="title title2">待办</h3>
        <ul class="todolist">
          <li @click="goUrl('/message/list?msgtype=1')">
            <img src="@/assets/images/db.svg" />
            <div class="cont">
              <div class="number">{{ backlogCount }}</div>
              <p>我的待办</p>
            </div>
          </li>
          <li @click="goUrl('/message/list?msgtype=2')">
            <img src="@/assets/images/yb.svg" />
            <div class="cont">
              <div class="number">{{ processedCount }}</div>
              <p>我的已办</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="white-box">
        <h3 class="title title2">消息<!--<span @click="setMsgAllRead">全部已读</span>--><span class="right" @click="goUrl('/message/list?msgtype=0')">查看更多</span></h3>
        <msg-list></msg-list>
      </div>
    </div>
  </div>
</template>

<script>
import { homeusedapp, homeallapp, homeotherapp } from '@/config/config.js'
import { msgPath } from '@/config/msg.js'
import { msgList, msgRead,homePc, msgAllRead } from '@/api/api_public'
import { mapState } from 'vuex'
import MsgList from '@/components/message/msglist'
export default {
  name: 'home',
  components: {
    MsgList
  },
  data() {
    return {
      homeusedapp,
      homeallapp,
      homeotherapp,
      msgPath,
      dimensionCfgList: [],
      reportCheckedList: [],
      noDoneNum: 0,
      messageList: [], //消息列表
      pagination: {
        page: 1,
        size: 10
      },
      messageId: null,
      msgtype: 1,//默认1
    }
  },
  computed: {
    ...mapState({
      backlogCount: state => state.msgNumber.backlogCount,
      processedCount: state => state.msgNumber.processedCount
    })
  },
  mounted() {
    this.getHomePc()
    console.log('pppppppppp', this.$route.query)
    if (this.$route.query.messageId) {
      console.log('pppppppppp22222第一步通过id  查询待办消息', this.$route.query.messageId)
      this.messageId = this.$route.query.messageId
      // import { msgList, msgRead, delMessage } from '@/api/api_public'
      // let daibanRes =  msgList(messageId)
      // daibanRes.success  >>> daibanRes.list[0].state ,type
      // 如果messageId 为空 或者 list里面无数据 ，返回 return
      // 满足条件 则跳转  msglistpage.vue >>>  goUrl(id, path, name, detailObj)
      // console.log('pppppppppp22222第二步消息列表不为空，跳转到对应的页面', this.$route.query.messageId)
      this.getMsgList();
    }
  },
  methods: {
    //设置已读
    async setMsgRead(id) {
      let { code, data } = await msgRead({ id })
      console.log("🚀 ~ file: Dashboard.vue:107 ~ setMsgRead ~ code:", code)
    },
    goUrlByMessageId(id, path, name, detailObj) {
      console.log('跳转参数====',id, path, name, detailObj)
      let paths = [301, 302, 303, 401, 402, 403]
      this.setMsgRead(id)
      if (path) {
        this.msgPath.forEach(ele => {
          if (ele.toPageType == path) {
            if (paths.includes(path)) {
              sessionStorage.removeItem('code')
              sessionStorage.removeItem('name')
              sessionStorage.setItem('code', ele.code)
              sessionStorage.setItem('name', name)
            }
            if ([501, 502].includes(path)) {
              console.log("🚀 ~ file: Dashboard.vue:123 ~ goUrlByMessageId ~ path:", ele.path)
              this.$router.push({
                name: ele.path,
                query: {
                  moduleName: 'review'
                }
              })
            } else {
              console.log('ele.path》》》》', ele.path)
              this.$router.push({ path: ele.path })
            }
          }
        })
        // path == 204 ? sessionStorage.setItem('batch-view', JSON.stringify(path)) : ''
      }
      //如果进入204待审阅 存个名字和id
      if (detailObj && path) {
        path == 204 ? sessionStorage.setItem('await-Obj', JSON.stringify(detailObj)) : ''
      }
    },
    //获取待办消息列表
    getMsgList() {
      if(!this.messageId){
        return
      }
      let params = {
        page: this.pagination.page,
        size: this.pagination.size,
        type: this.msgtype ,
        messageId:this.messageId
      }
      console.log("🚀 ~ file: Dashboard.vue:150 ~ getMsgList ~ params:", params)
      msgList(params).then(result => {
        console.log("🚀 ~ file: Dashboard.vue:147 ~ msgList ~ result:", result)
        if (result && result.code === '000000') {
          this.messageList = result.data.list
          if(this.messageList&&this.messageList.length>0){
            let data = this.messageList[0]
            this.goUrlByMessageId(data.id, data.toPageType, data.messageTypeName, data.toDetailsInput)
          }

        }
      })
    },
    getHomePc() {
      homePc().then(result => {
        if (result && result.code === '000000') {
          this.noDoneNum = result.data.noDoneNum
          this.dimensionCfgList = result.data.dimensionCfgList
          if (this.dimensionCfgList && this.dimensionCfgList.length > 0) {
            this.dimensionCfgList.forEach((ele, index) => {
              this.homeallapp.forEach(e => {
                if (ele.code === e.code) {
                  ele.path = e.path
                  ele.class = e.class
                  ele.icon = e.icon
                }
              })
            })
          }
          this.reportCheckedList = result.data.reportCheckedList
          if (this.reportCheckedList && this.reportCheckedList.length > 0) {
            this.homeusedapp.forEach((ele, index) => {
              this.reportCheckedList.forEach(e => {
                if (ele.reportType == e.reportType) {
                  ele.isAble = e.isAble
                  ele.isRedTips = e.isRedTips
                  ele.isRepeat = e.isRepeat
                }
              })
            })
          }
        }
      })
    },
    goAppUrl(item) {
      if (item.reportType == 1) {
        if (item.isRepeat === 1) {
          this.$warning('今日日清已经提交')
        } else {
          this.$router.push({ path: item.path })
        }
      } else if (item.reportType == 2) {
        if (item.isRepeat === 1) {
          this.$warning('本周周清已经提交')
        } else {
          this.$router.push({ path: item.path })
        }
      } else if (item.reportType == 3) {
        if (item.isRepeat === 1) {
          this.$warning('本月月清已经提交')
        } else {
          this.$router.push({ path: item.path })
        }
      } else {
        this.$router.push({ path: item.path })
      }
    },
    goUrl(path, item) {
      let paths = ['/innovations', '/customer', '/team', '/customer2']
      if (paths.includes(path)) {
        this.$router.push({ path, query: { code: item.code, name: item.displayName } })
        sessionStorage.removeItem('code')
        sessionStorage.removeItem('name')
        sessionStorage.setItem('code', item.code)
        sessionStorage.setItem('name', item.displayName)
        this.$store.commit('SET_TEAM_INFO', { code: item.code, name: item.displayName })
      } else {
        this.$router.push({ path })
      }
    },
    setMsgAllRead() {
      msgAllRead()
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  width: 100%;
  padding: 40px 40px 0;
  display: flex;
  justify-content: space-between;
  h3.title {
    font-size: 18px;
    line-height: 20px;
    color: $lightblack;
    position: relative;
    margin-bottom: 20px;
    span {
      color: $grey;
      font-size: 14px;
      font-weight: normal;
      margin-left: 15px;
      cursor: pointer;
      &:hover {
        color: $blue;
      }
    }
    span.right {
      position: absolute;
      right: 20px;
      top: 22px;
    }
  }
  h3.title2 {
    padding: 20px 20px 0;
    margin-bottom: 0;
  }
  &-left {
    width: calc(100% - 455px);
  }
  &-right {
    width: 455px;
    flex-shrink: 0;
    margin-top: 40px;
  }
  .applist {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    li {
      padding: 20px 15px;
      width: 245px;
      height: 80px;
      background: $white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
      border-radius: 5px;
      margin: 0 15px 15px 0;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      font-size: 14px;
      .iconfont {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        color: #ffffff;
        font-size: 18px;
        margin-right: 10px;
        position: relative;
        i {
          display: block;
          width: 11px;
          height: 11px;
          background: #ff526a;
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          border: 2px solid #fff;
        }
      }
      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .iconbg-yellow {
    background: $yellow;
  }
  .iconbg-green {
    background: $green;
  }
  .iconbg-blue {
    background: $blue;
  }
  .iconbg-red {
    background: $red;
  }
  .white-box {
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 30px;
  }
  .todolist {
    display: flex;
    padding: 20px;
    li {
      display: flex;
      align-items: center;
      flex-grow: 1;
      cursor: pointer;
      .number {
        color: $lightblack;
        font-size: 22px;
        font-family: 'DINCondensedBold';
      }
      p {
        color: $darkgrey;
        font-size: 14px;
      }
      img {
        margin-right: 16px;
      }
      &:hover {
        .number,
        p {
          color: $blue;
        }
      }
    }
    li:last-child {
      border-left: 1px solid $linecolor;
      padding-left: 20px;
    }
  }
}
</style>
